import * as React from 'react';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import {
    Formik,
    FormikProps,
    FormikValues,
    ErrorMessage,
    FormikErrors,
    Field,
    FieldArrayRenderProps,
    FieldProps,
    Form,
    useFormikContext,
} from 'formik';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { createFilter, Theme, MultiValue } from 'react-select';

import { getIsUserAdmin } from '@redux/modules/auth/selectors';
import CityInputNew from '@components/CityInputNew';
import Image from '@components/Icons/Image';
import GeoInputYandexNew from '@components/GeoInputYandexNew';
import { getLegalEntitiesList } from '@redux/modules/legal-entity/selectors';
import { getManagersList } from '@redux/modules/managers/selectors';
import Downloading from '@components/Icons/Downloading';
import { ButtonNew } from '@components/ButtonNew';
import QuestionMark from '@components/Icons/QuestionMark';
import Delete from '@components/Icons/Delete';
import Modal from '@components/Modal';
import {
    SubwayRoute,
    WorkingHours,
    WeekDaysOptions,
    SpecialPrice,
} from '@t/gym';
import { useSelectOptions } from 'hooks/useSelectOptions';
import { LegalEntity } from '@t/legal-entity';
import { SelectOption } from 'interfaces/SelectOption';
import { User } from '@t/user';
import { getMappedTagOptions } from '@utils/gym';
import { getTags } from '@redux/modules/tags/selectors';
import { Tag } from '@t/tag';
import { TagType } from 'enums/TagType';
import { AddGymFormValues } from 'interfaces/AddGymFormValues';
import { Portal as TooltipPortal } from '@components/Portal';
import { useStateRef } from 'hooks/useStateRef';
import {
    createGym,
    uploadImage,
    uploadGymIcon,
    deleteGym,
} from '@redux/modules/gyms/actions';
import { AsyncDispatch } from '@redux/types';
import { ClickAwayWrapper } from '@components/ClickAwayWrapper';

import UploadIcon from './UploadIcon';
import styles from './styles.module.css';
import {
    FormSection,
    TFormSectionRow,
    FormSectionRowSelectProps,
    FormSectionProps,
    TFormikSetFieldValue,
    TFormikErrors,
    GetRowsConfigProps,
} from './FormSection';
import { validate, isEmptyError, fieldErrorsMatrix } from './helpers';
import { AddGymSuperAdminHeader } from './AddGymSuperAdminHeader';
import { IAddGymErrors } from 'interfaces/GymFormData';

const scrollToElement = (errors: IAddGymErrors) => {
    const errorFieldName = (Object.entries(errors) as [
        string,
        string
    ][]).find(([, value]) => value?.trim?.());

    const ele = document.querySelector(`[name="${errorFieldName}"]`);

    if (ele) {
        const pos = ele.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
            top: pos - 100,
            behavior: 'smooth',
        });
    }
};

const ToggleGroupWorkoutsContent = React.memo<{
    onClose: () => void;
}>(({ onClose }) => {
    const { setFieldValue } = useFormikContext<AddGymFormValues>();

    return (
        <>
            <h3>Изменение групповых</h3>

            <p>
                Вы пытаетесь изменить формат зала.
                <br />
                Перед тем как скрыть из него групповые тренировки проверьте
                наличие <br /> бронирований на занятия в этой студии и ручным
                образом отмените все из них.
            </p>

            <button
                onClick={() => {
                    onClose();
                    setFieldValue('hasGroupWorkouts', true);
                }}
                type="button"
                className={styles.modalBtn}
            >
                Отмена
            </button>
            <button
                onClick={() => {
                    onClose();
                    setFieldValue('hasGroupWorkouts', false);
                }}
                type="button"
                className={styles.modalBtn}
            >
                Продолжить
            </button>
        </>
    );
});

const UploadPhotosDropzone = React.memo<{
    closeDropzone: () => void;
}>(({ closeDropzone }) => {
    const { values, setFieldValue } = useFormikContext<AddGymFormValues>();

    const onDropGymPhoto = React.useCallback(
        async (acceptedFiles: File[]) => {
            if (!acceptedFiles.length) {
                cogoToast.error(
                    `Можно загрузить файлы с расширением png, jpeg, jpg, webp`,
                    {
                        position: 'top-right',
                        hideAfter: 4,
                    }
                );
                return;
            } else {
                const currentPhotos = values.previewSources?.images ?? [];

                const MAX_LENGTH = 5;
                const MAX_SIZE = 5000000;
                const numOfFiles = Array.from(acceptedFiles).length;

                if (numOfFiles + currentPhotos.length > MAX_LENGTH) {
                    cogoToast.error(
                        `Нельзя загрузить более ${MAX_LENGTH} фото`,
                        {
                            position: 'top-right',
                            hideAfter: 4,
                        }
                    );
                    return;
                }
                for (let i = 0; i < numOfFiles; ++i) {
                    const validExtensions = ['png', 'jpeg', 'jpg', 'webp'];
                    const fileExtension = acceptedFiles[i].type.split('/')[1];
                    const fileSize = acceptedFiles[i].size;

                    if (!validExtensions.includes(fileExtension)) {
                        cogoToast.error(
                            `Можно загрузить документы с расширением png, jpeg, jpg, webp`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }

                    if (fileSize > MAX_SIZE) {
                        cogoToast.error(
                            `Нельзя загрузить фото размера более 5MB, фото "${acceptedFiles[i].name}" слишком большое`,
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                        return;
                    }
                }

                closeDropzone();

                const existingImages = Array.isArray(
                    values.previewSources?.images
                )
                    ? values.previewSources?.images ?? []
                    : Array.from(values.previewSources?.images ?? []);

                const newImages = [...existingImages, ...acceptedFiles];

                setFieldValue('previewSources.images', newImages);
            }
        },
        [values.previewSources?.images]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropGymPhoto,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
        },
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <>
                    <div className={styles.drop}>
                        <div className={styles.dropZone}>
                            <div className={styles.dropTitle}>
                                Загрузка фото
                            </div>
                            <div className={styles.uploadPhotosDropdownContent}>
                                <Downloading />
                                <span className={styles.dropSubTitle}>
                                    Отпустите для загрузки файла
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.drop}>
                        <div className={styles.dropZone}>
                            <div className={styles.dropTitle}>
                                Загрузка фото
                            </div>
                            <div className={styles.uploadPhotosDropdownContent}>
                                <Image />
                                <span className={styles.dropSubTitle}>
                                    Перетащите файл в выделенную область
                                </span>
                                <span>
                                    или нажмите для выбора из проводника
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export interface IToolTips {
    groupWorkoutsOption: boolean;
    yclientsId: boolean;
    currentAnchor: HTMLElement | null;
}

const renderFormSection = (otherProps: {
    setFieldValue: TFormikSetFieldValue<FormikValues>;
}) => (props: FormSectionProps<FormikValues>, index: number) => (
    <FormSection
        key={['formSection', index].join('_')}
        {...props}
        {...otherProps}
    />
);

export const AddGymForm = React.memo(() => {
    const isAdmin = useSelector(getIsUserAdmin);

    const dispatch = useDispatch<AsyncDispatch>();
    const history = useHistory();

    const { options: allManagersOptions } = useSelectOptions<User>({
        selector: getManagersList,
        valueAlias: '_id',
        getCustomLabel: (entity) => `${entity.firstName}  -  ${entity.email}`,
    });

    const { options: legalEntitysOptions } = useSelectOptions<LegalEntity>({
        selector: getLegalEntitiesList,
        valueAlias: '_id',
        labelAlias: 'name',
    });

    const { options: gymServicesOptions } = useSelectOptions<Tag>({
        selector: getTags,
        customMapper: (tagsList) =>
            getMappedTagOptions(tagsList, TagType.GymService),
    });

    const { options: gymAdvantagesOptions } = useSelectOptions<Tag>({
        selector: getTags,
        customMapper: (tagsList) =>
            getMappedTagOptions(tagsList, TagType.GymAdvantage),
    });

    const [
        ,
        setCurrentFocusedSubwayRouteFieldRef,
        currentFocusedSubwayRouteFieldRef,
    ] = useStateRef<string | null>(null);

    const [wasMapShown, setWasMapShown] = React.useState(false);
    const [
        uploadPhotosModalActive,
        setUploadPhotosModalActive,
    ] = React.useState<{
        setFieldValue: TFormikSetFieldValue<AddGymFormValues>;
    } | null>(null);
    const [
        activeGroupWorkoutsModal,
        setActiveGroupWorkoutsModal,
    ] = React.useState<{
        setFieldValue: TFormikSetFieldValue<AddGymFormValues>;
    } | null>(null);

    const handleClickAway = React.useCallback(() => {
        setCurrentFocusedSubwayRouteFieldRef(null);
    }, []);

    const initialValues = React.useMemo((): AddGymFormValues => {
        return {
            tags: { shower: true },
            managersId: [],
            workingHours: [],
            subwayRoutes: [],
            services: [],
            specialPrices: [],
            hasPerMinWorkouts: true,
        };
    }, []);

    const [showYClients, setShowYClients] = React.useState(
        !!initialValues.yclientsId
    );

    const [shortAddressCount, setShortAddressCount] = React.useState(
        initialValues.geo?.shortAddress?.length ?? 0
    );

    const [tooltips, setTooltips] = React.useState<IToolTips>({
        groupWorkoutsOption: false,
        yclientsId: false,
        currentAnchor: null,
    });

    const handleLifecyclePersistenceRef = React.useCallback(
        (name: string) => (ref: HTMLDivElement) => {
            if (ref) {
                ref.addEventListener('blur', () => {
                    if (currentFocusedSubwayRouteFieldRef.current === name) {
                        setTimeout(() => {
                            const inputNode = document.querySelector(
                                `input[name="${name}"]`
                            ) as HTMLInputElement;

                            inputNode?.focus();
                        });
                    }
                });

                ref.addEventListener('focus', () => {
                    currentFocusedSubwayRouteFieldRef.current = name;
                });
            }
        },
        []
    );

    const tooltipsMatrix: Record<
        keyof Omit<IToolTips, 'currentAnchor'>,
        { tooltip: React.ReactNode; style: React.CSSProperties }
    > = {
        groupWorkoutsOption: {
            tooltip: (
                <div className={styles.tooltip}>
                    <p>
                        Данная функция разблокирует опцию групповых тренировок в
                        приложении
                    </p>
                    <p>
                        Для того чтобы тренировки появились нужно заполнить
                        календарь в разделе{' '}
                        <span className={styles.tooltipLink}>
                            Групповые тренировки
                        </span>
                    </p>
                    <p>
                        Перед тем как переходить по ссылке сохраните все
                        изменения на странице
                    </p>
                    <div
                        onClick={() =>
                            setTooltips((prevState) => ({
                                ...prevState,
                                currentAnchor: null,
                                groupWorkoutsOption: false,
                            }))
                        }
                    >
                        <Delete className={styles.tooltipClose} />
                    </div>
                </div>
            ),
            style: {},
        },
        yclientsId: {
            tooltip: (
                <div className={styles.tooltip}>
                    <p>
                        Для того, чтобы найти ID компании, в системе YCLIENTS
                        выберите в боковом меню кнопку Обзор и далее Сводка
                    </p>
                    <p>
                        Скопируйте номер, показанный в поле Идентификатор
                        филиала (ID) без символа #
                    </p>
                    <p>
                        Если ваша компания не является партнером YCLIENTS,
                        просто оставьте данное поле пустым.
                    </p>
                    <div
                        onClick={() =>
                            setTooltips((prevState) => ({
                                ...prevState,
                                currentAnchor: null,
                                yclientsId: false,
                            }))
                        }
                    >
                        <Delete className={styles.tooltipClose} />
                    </div>
                </div>
            ),
            style: {},
        },
    };

    const getParameterRowsConfig = React.useCallback(
        ({ values, errors }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            rowStyles[rows.length] = { marginBottom: 16 };
            rows.push([
                {
                    name: 'title',
                    label: 'Название площадки',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'Название площадки',
                    isError: !!errors['title'],
                },
                !!values.hasGroupWorkouts && {
                    name: 'prepayment',
                    label:
                        'Размер предоплаты (значение не может быть меньше 50%)',
                    disabled: isAdmin,
                    type: 'input',
                    isError: !!errors['prepayment'],
                    placeholder: 'Размер предоплаты',
                },
            ]);

            rows.push([
                {
                    name: 'hasPerMinWorkouts',
                    label: 'Поминутные',
                    type: 'checkbox',
                    gutterBottom: true,
                    style: {
                        flex: 0,
                    },
                },
                !!values.withGroupWorkoutsOption && {
                    name: 'hasGroupWorkouts',
                    label: 'Групповые',
                    type: 'checkbox',
                    gutterBottom: true,
                    rightAdornment: (
                        <div>
                            <span
                                title="Подсказка про опцию групповых тренировок"
                                className={classnames(styles.infoYC, {
                                    [styles.infoYCInctive]: !activeTooltip,
                                })}
                                onClick={(e) =>
                                    setTooltips((prevState) => ({
                                        ...prevState,
                                        groupWorkoutsOption: true,
                                        currentAnchor: e.currentTarget,
                                    }))
                                }
                            >
                                <QuestionMark />
                            </span>
                        </div>
                    ),
                },
                !!values.withMembershipsOption && {
                    name: 'hasMemberships',
                    label: 'Абонементы',
                    type: 'checkbox',
                    gutterBottom: true,
                    disabled: isAdmin,
                },
            ]);

            rowStyles[rows.length] = { marginBottom: 16 };
            rows.push([
                {
                    name: 'hasPassport',
                    label: 'Нужен паспорт',
                    type: 'checkbox',
                    disabled: isAdmin,
                    gutterBottom: true,
                },
            ]);

            if (values.hasGroupWorkouts) {
                if (showYClients) {
                    rows.push([
                        {
                            name: 'yclientsId',
                            label: 'YCLIENTS ID',
                            type: 'input',
                            disabled: isAdmin,
                            gutterBottom: true,
                            placeholder: 'ID компании в системе YCLIENTS',
                            isError: !!errors['yclientsId'],
                        },
                        {
                            name: 'discountFromYclientsPrice',
                            label: '% скидки от цены YCLIENTS',
                            type: 'input',
                            disabled: isAdmin,
                            gutterBottom: true,
                            placeholder: 'Скидка',
                            isError: !!errors['discountFromYclientsPrice'],
                        },
                        {
                            name: 'prepaymentPercentFromYclientsPrice',
                            label: '% предоплаты',
                            type: 'input',
                            disabled: isAdmin,
                            gutterBottom: true,
                            placeholder: 'Предоплата',
                            isError: !!errors[
                                'prepaymentPercentFromYclientsPrice'
                            ],
                        },
                    ]);
                } else {
                    rows.push([
                        {
                            node: (
                                <div className={styles.row}>
                                    <ButtonNew
                                        disabled={isAdmin}
                                        appearance="plus"
                                        icon="plus"
                                        onClick={() =>
                                            setShowYClients((prev) => !prev)
                                        }
                                    >
                                        Добавить YCLIENTS ID
                                    </ButtonNew>
                                    <span
                                        title="Подсказка про опцию yclients"
                                        className={classnames(styles.infoYC, {
                                            [styles.infoYCInctive]: !activeTooltip,
                                        })}
                                        onClick={(e) =>
                                            setTooltips((prevState) => ({
                                                ...prevState,
                                                yclientsId: !prevState.yclientsId,
                                                currentAnchor: e.currentTarget,
                                            }))
                                        }
                                    >
                                        <QuestionMark /> Что это
                                    </span>
                                </div>
                            ),
                        },
                    ]);
                }
            }

            return { rows, rowStyles };
        },
        [showYClients, isAdmin]
    );

    const onSubmit = React.useCallback(async (values: AddGymFormValues) => {
        try {
            const gym = await dispatch(createGym(values));

            if (gym) {
                try {
                    const promises: any[] = [];

                    if (values.previewSources?.images.length) {
                        promises.push(
                            dispatch(
                                uploadImage({
                                    files: values.previewSources.images,
                                    gymId: gym._id,
                                })
                            )
                        );
                    }

                    const { icon } = values.previewSources ?? {};

                    if (icon) {
                        promises.push(
                            dispatch(
                                uploadGymIcon({
                                    files: [icon],
                                    gymId: gym._id,
                                })
                            )
                        );
                    }

                    await Promise.all(promises);

                    cogoToast.success('Фитнес площадка создана', {
                        position: 'top-right',
                        hideAfter: 5,
                    });

                    history.push(['/dashboard/gyms', gym._id].join('/'));
                } catch (err) {
                    await dispatch(deleteGym(gym._id));

                    throw err;
                }
            }
        } catch (err) {
            cogoToast.error('Ошибка при создании фитнес-площадки', {
                position: 'top-right',
                hideAfter: 4,
            });
        }
    }, []);

    const getTagsRowsConfig = React.useCallback(
        ({ values, setFieldValue }: GetRowsConfigProps) => {
            const getCurrentGymAdvantagesOptions = (advantages?: string[]) =>
                gymAdvantagesOptions.filter((gymAdvantage) =>
                    advantages?.includes(gymAdvantage.value)
                );

            const getCurrentGymServicesOptions = (services: string[]) =>
                gymServicesOptions.filter((gymService) =>
                    services?.includes(gymService.value)
                );

            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            const adjustSelectTheme = (theme: Theme) => ({
                ...theme,
                colors: { ...theme.colors, primary: 'rgb(225, 129, 65)' },
            });

            const commonSelectProps: Partial<
                FormSectionRowSelectProps<AddGymFormValues>
            > = {
                type: 'select',
                disabled: isAdmin,
                noOptionsMessage: 'Ничего не найдено',
                placeholder: 'Выберите из списка',
                selectProps: {
                    isMulti: true,
                    closeMenuOnSelect: false,
                    filterOption: createFilter({
                        stringify: (option) => `${option.label}`,
                    }),
                    theme: adjustSelectTheme,
                    styles: {
                        input: (provided) => ({
                            ...provided,
                            minHeight: 40,
                            borderRadius: '8px',
                        }),
                    },
                },
            };

            rowStyles[rows.length] = { position: 'relative', zIndex: 5 };
            rows.push([
                {
                    ...commonSelectProps,
                    label: 'Услуги зала',
                    name: 'services',
                    onSelectChange: (_e) => {
                        const e = (_e as unknown) as MultiValue<SelectOption>;

                        setFieldValue(
                            'services',
                            e.map((el) => el.value)
                        );
                    },
                    value: getCurrentGymServicesOptions(
                        values.services as string[]
                    ),
                    options: gymServicesOptions,
                } as FormSectionRowSelectProps<AddGymFormValues>,
                {
                    ...commonSelectProps,
                    label: 'Преимущества зала',
                    name: 'advantages',
                    onSelectChange: (_e) => {
                        const e = (_e as unknown) as MultiValue<SelectOption>;

                        setFieldValue(
                            'advantages',
                            e.map((el) => el.value)
                        );
                    },
                    value: getCurrentGymAdvantagesOptions(values.advantages),
                    options: gymAdvantagesOptions,
                } as FormSectionRowSelectProps<AddGymFormValues>,
            ]);

            return { rows, rowStyles };
        },
        [gymAdvantagesOptions, gymServicesOptions, isAdmin]
    );

    const getGymByMinuteRowsConfig = React.useCallback(
        ({ values, setFieldValue }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            rows.push([
                {
                    name: 'fixedPrice',
                    label: 'Стоимость',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: '0 ₽',
                    gutterBottom: true,
                    style: {
                        width: '100%',
                    },
                },
                !!values.hasMinWorkoutDuration && {
                    name: 'minWorkoutDuration',
                    label: 'Минимальное время тренировки',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: '0 мин.',
                    gutterBottom: true,
                },
            ]);

            const byMinuteConfig = {
                specialPrices: values.specialPrices,
                arrayHelpers: null,
            } as {
                specialPrices: SpecialPrice[];
                arrayHelpers: FieldArrayRenderProps | null;
            };

            rows.push([
                {
                    name: 'specialPrices',
                    type: 'fieldArray',
                    style: {},
                    gutterBottom: true,
                    render: (arrayHelpers) => {
                        byMinuteConfig.arrayHelpers = arrayHelpers;
                        const specialPrices = byMinuteConfig.specialPrices;
                        const nodes: (() => JSX.Element)[] = [];

                        specialPrices.forEach((_, index) => {
                            const weekdayName = `specialPrices.${index}.weekDay`;
                            const timeStartName = `specialPrices.${index}.timeStart`;
                            const timeFinishName = `specialPrices.${index}.timeFinish`;
                            const priceName = `specialPrices.${index}.price`;

                            nodes.push(() => (
                                <div
                                    className={
                                        styles.specialPriceElementContainer
                                    }
                                >
                                    <label
                                        className={styles.workingHoursLabel}
                                        htmlFor="timeStart"
                                        style={{ flex: 1 }}
                                    >
                                        День недели
                                        <Field
                                            disabled={isAdmin}
                                            className={styles.selectDay}
                                            as="select"
                                            name={weekdayName}
                                        >
                                            <option value="">
                                                Выбрать день/период
                                            </option>
                                            {WeekDaysOptions.map(
                                                (WeekDaysOption) => {
                                                    return (
                                                        <option
                                                            key={
                                                                WeekDaysOption.value
                                                            }
                                                            value={
                                                                WeekDaysOption.value
                                                            }
                                                        >
                                                            {
                                                                WeekDaysOption.title
                                                            }
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={styles.error}
                                            name={weekdayName}
                                            component="div"
                                        />
                                    </label>
                                    <label
                                        className={styles.workingHoursLabel}
                                        htmlFor="timeStart"
                                        style={{ flex: 1 }}
                                    >
                                        Начало
                                        <Field
                                            className={styles.workingHoursInput}
                                            id="timeStart"
                                            name={timeStartName}
                                        >
                                            {({ field }: FieldProps) => (
                                                <>
                                                    <TimePicker
                                                        disabled={isAdmin}
                                                        name={field.name}
                                                        showSecond={false}
                                                        defaultValue={
                                                            field.value
                                                                ? moment(
                                                                      field.value
                                                                  ).utcOffset(0)
                                                                : moment()
                                                                      .utcOffset(
                                                                          0
                                                                      )
                                                                      .startOf(
                                                                          'day'
                                                                      )
                                                        }
                                                        onChange={(value) => {
                                                            setFieldValue(
                                                                timeStartName,
                                                                moment(
                                                                    value.utcOffset(
                                                                        0
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                        hideDisabledOptions
                                                        className={
                                                            styles.inputTimePicker
                                                        }
                                                        clearIcon={null}
                                                    />
                                                </>
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={styles.error}
                                            name={timeStartName}
                                            component="div"
                                        />
                                    </label>
                                    <label
                                        className={styles.workingHoursLabel}
                                        htmlFor="timeFinish"
                                        style={{ flex: 1 }}
                                    >
                                        Завершение
                                        <Field
                                            className={styles.workingHoursInput}
                                            id="timeFinish"
                                            name={timeFinishName}
                                        >
                                            {({ field }: FieldProps) => (
                                                <>
                                                    <TimePicker
                                                        disabled={isAdmin}
                                                        name={field.name}
                                                        showSecond={false}
                                                        defaultValue={
                                                            field.value
                                                                ? moment(
                                                                      field.value
                                                                  ).utcOffset(0)
                                                                : moment()
                                                                      .utcOffset(
                                                                          0
                                                                      )
                                                                      .startOf(
                                                                          'day'
                                                                      )
                                                        }
                                                        onChange={(value) => {
                                                            setFieldValue(
                                                                timeFinishName,
                                                                moment(
                                                                    value.utcOffset(
                                                                        0
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                        hideDisabledOptions
                                                        className={
                                                            styles.inputTimePicker
                                                        }
                                                        clearIcon={null}
                                                    />
                                                </>
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            className={styles.error}
                                            name={timeFinishName}
                                            component="div"
                                        />
                                    </label>
                                    <label
                                        className={styles.specialPricesLabel}
                                        htmlFor="price"
                                        style={{ flex: 1 }}
                                    >
                                        Стоимость
                                        <ClickAwayWrapper
                                            onClickAway={handleClickAway}
                                        >
                                            <Field
                                                disabled={isAdmin}
                                                className={
                                                    styles.workingHoursInput
                                                }
                                                id="price"
                                                name={priceName}
                                                placeholder="0 ₽"
                                                innerRef={handleLifecyclePersistenceRef(
                                                    priceName
                                                )}
                                            />
                                        </ClickAwayWrapper>
                                        <ErrorMessage
                                            className={styles.error}
                                            name={priceName}
                                            component="div"
                                        />
                                    </label>
                                    <button
                                        disabled={isAdmin}
                                        type="button"
                                        className={styles.specialPricesDelBtn}
                                        onClick={() =>
                                            arrayHelpers.remove(index)
                                        }
                                    >
                                        &#10060;
                                    </button>
                                </div>
                            ));
                        });

                        return (
                            <>
                                {nodes.map((Component, index) => (
                                    <Component
                                        key={['specialPrices', index].join('')}
                                    />
                                ))}
                            </>
                        );
                    },
                },
            ]);

            rows.push([
                {
                    node: (
                        <ButtonNew
                            disabled={isAdmin}
                            type="button"
                            appearance="plus"
                            icon="plus"
                            onClick={() => {
                                byMinuteConfig.arrayHelpers?.push({
                                    weekDay: '',
                                    timeStart: '',
                                    timeFinish: '',
                                    price: '',
                                });
                            }}
                        >
                            Специальная цена
                        </ButtonNew>
                    ),
                },
            ]);

            return {
                rows,
                rowStyles,
            };
        },
        [isAdmin]
    );

    const getGymContactsRowsConfig = React.useCallback(
        ({ setFieldValue }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            rows.push([
                {
                    name: 'social.telegram',
                    label: 'Телеграм',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 't.me/',
                    gutterBottom: true,
                },
                {
                    name: 'website',
                    label: 'Ссылка на сайт',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'Введите адрес сайта',
                    gutterBottom: true,
                },
            ]);

            rows.push([
                {
                    name: 'social.vk',
                    label: 'VK (только ID)',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'vk.com/',
                },
                {
                    node: (
                        <label
                            htmlFor="phone"
                            className={classnames(
                                styles.gymLabel,
                                styles.gymLabelInputMask
                            )}
                        >
                            Номер телефона
                            <Field disabled={isAdmin} id="phone" name="phone">
                                {({ field }: FieldProps) => (
                                    <ReactInputMask
                                        disabled={isAdmin}
                                        {...field}
                                        className={styles.gymTitle1}
                                        placeholder="+7"
                                        mask="+7 (999) 999-99-99"
                                        maskChar={null}
                                        onChange={(e) => {
                                            const { value } = e.target;

                                            if (value === '+7 (') {
                                                setFieldValue('phone', '');
                                                return;
                                            }

                                            setFieldValue('phone', value);
                                        }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage
                                className={styles.error}
                                name="phone"
                                component="div"
                            />
                        </label>
                    ),
                },
            ]);
            return {
                rows,
                rowStyles,
            };
        },
        [isAdmin]
    );

    const getGymDescriptionRowsConfig = React.useCallback(() => {
        const rows: TFormSectionRow<AddGymFormValues>[][] = [];
        const rowStyles: Record<string, React.CSSProperties> = {};

        rows.push([
            {
                name: 'description',
                label: 'Описание',
                disabled: isAdmin,
                type: 'input',
                placeholder: 'Введите описание',
                otherProps: {
                    as: 'textarea',
                },
            },
            {
                node: (
                    <div className={styles.description}>
                        Коротко опишите главные преимущества вашей площадки.
                        Время работы заполняется отдельно
                    </div>
                ),
            },
        ]);

        return {
            rows,
            rowStyles,
        };
    }, [isAdmin]);

    const getGymIconRowsConfig = React.useCallback(
        ({ values, setFieldValue }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            rows.push([
                {
                    node: (
                        <UploadIcon
                            iconSrc={
                                values.previewSources?.icon
                                    ? URL.createObjectURL(
                                          values.previewSources.icon
                                      )
                                    : ''
                            }
                            onAccepted={([file]) => {
                                setFieldValue('previewSources.icon', file);
                            }}
                            onDelete={() => {
                                setFieldValue('previewSources.icon', null);
                            }}
                            className={styles.uploadGymIcon}
                            gymImageClassName={styles.gymImage}
                        />
                    ),
                },
            ]);

            return { rows, rowStyles };
        },
        []
    );

    const getGymImagesRowsConfig = React.useCallback(
        ({ values, setFieldValue }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            const gymsImagesUrls = Array.from(
                values.previewSources?.images || []
            );

            rows.push([
                {
                    node: (
                        <div className={styles.images}>
                            {gymsImagesUrls.map((file, index) => {
                                const url = URL.createObjectURL(file);

                                return (
                                    <div
                                        key={['image', index].join('_')}
                                        className={styles.gymImageContainer}
                                    >
                                        <img
                                            id={`img${index}`}
                                            src={url}
                                            className={styles.gymImage}
                                        />
                                        <button
                                            type="button"
                                            className={styles.DeleteImageBtn}
                                            onClick={() => {
                                                setFieldValue(
                                                    'previewSources.images',
                                                    gymsImagesUrls.filter(
                                                        ({ name }) =>
                                                            name !== file.name
                                                    )
                                                );
                                            }}
                                        >
                                            &#10060;
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    ),
                },
            ]);
            rows.push([
                {
                    node: (
                        <ButtonNew
                            disabled={isAdmin}
                            type="button"
                            appearance="plus"
                            icon="plus"
                            onClick={() =>
                                setUploadPhotosModalActive({ setFieldValue })
                            }
                        >
                            Загрузить фото
                        </ButtonNew>
                    ),
                },
            ]);

            return {
                rows,
                rowStyles,
            };
        },
        []
    );

    const getGymWorkingHoursRowsConfig = React.useCallback(
        ({ values, setFieldValue }: GetRowsConfigProps) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            const workingHoursConfig = {
                workingHours: values.workingHours,
                arrayHelpers: null,
            } as {
                workingHours: WorkingHours[];
                arrayHelpers: FieldArrayRenderProps | null;
            };
            rows.push([
                {
                    name: 'workingHours',
                    type: 'fieldArray',
                    style: {
                        flex: 0,
                    },
                    render: (arrayHelpers) => {
                        workingHoursConfig.arrayHelpers = arrayHelpers;
                        const workingHours = workingHoursConfig.workingHours;
                        const nodes: (() => JSX.Element)[] = [];

                        workingHours.forEach((_, index) => {
                            const weekdayName = `workingHours.${index}.weekDay`;
                            const aroundTheClockName = `workingHours.${index}.aroundTheClock`;
                            const timeStartName = `workingHours.${index}.timeStart`;
                            const timeFinishName = `workingHours.${index}.timeFinish`;

                            nodes.push(() => (
                                <div
                                    key={index}
                                    className={styles.customNodeContainer}
                                >
                                    <div
                                        className={styles.workingHoursInputs}
                                        key={index}
                                    >
                                        <label
                                            className={styles.workingHoursLabel}
                                            htmlFor="timeStart"
                                        >
                                            День недели
                                            <Field
                                                disabled={isAdmin}
                                                className={styles.selectDay}
                                                as="select"
                                                name={weekdayName}
                                            >
                                                <option value="">
                                                    Выбрать день/период
                                                </option>
                                                {WeekDaysOptions.map(
                                                    (WeekDaysOption) => {
                                                        return (
                                                            <option
                                                                key={
                                                                    WeekDaysOption.value
                                                                }
                                                                value={
                                                                    WeekDaysOption.value
                                                                }
                                                            >
                                                                {
                                                                    WeekDaysOption.title
                                                                }
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                className={styles.error}
                                                name={weekdayName}
                                                component="div"
                                            />
                                        </label>
                                        <span className={styles.aroundTheClock}>
                                            Круглосуточно
                                        </span>
                                        <label className={styles.switch}>
                                            <Field
                                                disabled={isAdmin}
                                                className={styles.toggle}
                                                type="checkbox"
                                                name={aroundTheClockName}
                                            />
                                            <span className={styles.slider} />
                                        </label>
                                        <label
                                            className={styles.workingHoursLabel}
                                            htmlFor="timeStart"
                                        >
                                            Время открытия
                                            <Field
                                                disabled={isAdmin}
                                                className={
                                                    styles.workingHoursInput
                                                }
                                                id="timeStart"
                                                name={timeStartName}
                                            >
                                                {({ field }: FieldProps) => (
                                                    <>
                                                        <TimePicker
                                                            disabled={isAdmin}
                                                            name={field.name}
                                                            value={field.value}
                                                            showSecond={false}
                                                            placeholder={
                                                                'Выберите время'
                                                            }
                                                            defaultValue={
                                                                field.value
                                                                    ? moment(
                                                                          field.value
                                                                      ).utcOffset(
                                                                          0
                                                                      )
                                                                    : moment()
                                                                          .utcOffset(
                                                                              0
                                                                          )
                                                                          .startOf(
                                                                              'day'
                                                                          )
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    timeStartName,
                                                                    value
                                                                );
                                                            }}
                                                            hideDisabledOptions
                                                            //@ts-expect-error
                                                            autoComplete="off"
                                                            className={
                                                                styles.inputTimePicker
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                className={styles.error}
                                                name={timeStartName}
                                                component="div"
                                            />
                                        </label>
                                        <label
                                            className={styles.workingHoursLabel}
                                            htmlFor="timeFinish"
                                        >
                                            Время закрытия
                                            <Field
                                                disabled={isAdmin}
                                                className={
                                                    styles.workingHoursInput
                                                }
                                                id="timeFinish"
                                                name={timeFinishName}
                                            >
                                                {({ field }: FieldProps) => (
                                                    <>
                                                        <TimePicker
                                                            disabled={isAdmin}
                                                            name={field.name}
                                                            showSecond={false}
                                                            value={field.value}
                                                            placeholder={
                                                                'Выберите время'
                                                            }
                                                            defaultValue={
                                                                field.value
                                                                    ? moment(
                                                                          field.value
                                                                      ).utcOffset(
                                                                          0
                                                                      )
                                                                    : moment()
                                                                          .utcOffset(
                                                                              0
                                                                          )
                                                                          .startOf(
                                                                              'day'
                                                                          )
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    timeFinishName,
                                                                    value
                                                                );
                                                            }}
                                                            hideDisabledOptions
                                                            //@ts-expect-error
                                                            autoComplete="off"
                                                            className={
                                                                styles.inputTimePicker
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                className={styles.error}
                                                name={timeFinishName}
                                                component="div"
                                            />
                                        </label>
                                        <button
                                            disabled={isAdmin}
                                            type="button"
                                            className={
                                                styles.deleteWorkingHoursBtn
                                            }
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            &#10060;
                                        </button>
                                    </div>
                                </div>
                            ));
                        });

                        return (
                            <>
                                {nodes.map((Component, index) => (
                                    <Component
                                        key={['subwayRoutes', index].join('')}
                                    />
                                ))}
                            </>
                        );
                    },
                },
            ]);

            rows.push([
                {
                    node: (
                        <ButtonNew
                            disabled={isAdmin}
                            type="button"
                            appearance="plus"
                            icon="plus"
                            onClick={() => {
                                workingHoursConfig.arrayHelpers?.push({
                                    weekDay: '',
                                    aroundTheClock: false,
                                    timeStart: '',
                                    timeFinish: '',
                                });
                            }}
                        >
                            Добавить время работы
                        </ButtonNew>
                    ),
                },
            ]);

            return {
                rows,
                rowStyles,
            };
        },
        [isAdmin]
    );

    const getGymInfoRowsConfig = React.useCallback(
        ({
            values,
            errors,
        }: {
            values: AddGymFormValues;
            errors: FormikErrors<AddGymFormValues>;
        }) => {
            const rows: TFormSectionRow<AddGymFormValues>[][] = [];
            const rowStyles: Record<string, React.CSSProperties> = {};

            rowStyles[rows.length] = { paddingTop: 24 };

            rows.push([
                {
                    name: 'city',
                    label: 'Город',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'Введите город',
                    isError: !!errors['city'],
                    otherProps: {
                        as: CityInputNew,
                    },
                },
                {
                    name: 'geo',
                    label: 'Полный адрес',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'Выберите из списка',
                    className: styles.geoInputClassName,
                    isError: !!errors['geo'],
                    otherProps: {
                        as: GeoInputYandexNew,
                        setShortAddressCount,
                        errorMessage: ErrorMessage,
                        onMapShown: setWasMapShown,
                    },
                },
            ]);

            rows.push([
                {
                    name: 'shortAddress',
                    label: 'Короткий адрес',
                    disabled: isAdmin,
                    type: 'input',
                    placeholder: 'Введите адрес',
                    isError: !!errors['shortAddress'],
                    style: {
                        flex: 0,
                    },
                    renderErrorNode: (
                        errors: FormikErrors<AddGymFormValues>
                    ) => {
                        // @ts-expect-error
                        if (!errors.geo?.shortAddress) {
                            return (
                                <span
                                    className={
                                        shortAddressCount > 38
                                            ? styles.addressCountError
                                            : styles.addressCount
                                    }
                                >
                                    {shortAddressCount}/38
                                </span>
                            );
                        }
                    },
                },
            ]);

            const subwayRoutesConfig = {
                subwayRoutes: values.subwayRoutes,
                arrayHelpers: null,
            } as {
                subwayRoutes: SubwayRoute[];
                arrayHelpers: FieldArrayRenderProps | null;
            };
            rows.push([
                {
                    name: 'subwayRoutes',
                    type: 'fieldArray',
                    style: {
                        flex: 0,
                    },
                    render: (arrayHelpers) => {
                        subwayRoutesConfig.arrayHelpers = arrayHelpers;
                        const subwayRoutes = subwayRoutesConfig.subwayRoutes;
                        const nodes: (() => JSX.Element)[] = [];

                        subwayRoutes.forEach((_, index) => {
                            const stationName = `subwayRoutes.${index}.station`;
                            const durationName = `subwayRoutes.${index}.duration`;

                            nodes.push(() => (
                                <div
                                    key={index}
                                    className={styles.customNodeContainer}
                                >
                                    <label
                                        htmlFor="station"
                                        className={styles.subwayLabel}
                                    >
                                        Станция метро (если есть){' '}
                                        <ClickAwayWrapper
                                            onClickAway={handleClickAway}
                                        >
                                            <Field
                                                id="station"
                                                innerRef={handleLifecyclePersistenceRef(
                                                    stationName
                                                )}
                                                className={styles.input}
                                                name={stationName}
                                                placeholder={'Название станции'}
                                            />
                                        </ClickAwayWrapper>
                                        <ErrorMessage
                                            className={classnames(
                                                styles.error,
                                                styles.customError
                                            )}
                                            name={stationName}
                                            component="div"
                                        />
                                    </label>

                                    <label
                                        htmlFor="duration"
                                        className={styles.subwayLabel}
                                    >
                                        Время от метро{' '}
                                        <ClickAwayWrapper
                                            onClickAway={handleClickAway}
                                        >
                                            <Field
                                                id="duration"
                                                innerRef={handleLifecyclePersistenceRef(
                                                    durationName
                                                )}
                                                className={styles.inputAlt}
                                                name={durationName}
                                                placeholder={'Минуты'}
                                            />
                                        </ClickAwayWrapper>
                                        <ErrorMessage
                                            className={classnames(
                                                styles.error,
                                                styles.customError
                                            )}
                                            name={durationName}
                                            component="div"
                                        />
                                    </label>
                                    <button
                                        disabled={isAdmin}
                                        type="button"
                                        className={styles.deleteWorkingHoursBtn}
                                        onClick={() => {
                                            arrayHelpers.remove(index);
                                        }}
                                        style={{
                                            marginTop: 24,
                                        }}
                                    >
                                        &#10060;
                                    </button>
                                </div>
                            ));
                        });

                        return (
                            <>
                                {nodes.map((Component, index) => (
                                    <Component
                                        key={['subwayRoutes', index].join('')}
                                    />
                                ))}
                            </>
                        );
                    },
                },
            ]);

            if ((subwayRoutesConfig.subwayRoutes?.length ?? 0) < 3) {
                rowStyles[rows.length] = { marginTop: 24 };
                rows.push([
                    {
                        node: (
                            <ButtonNew
                                disabled={isAdmin}
                                type="button"
                                appearance="plus"
                                icon="plus"
                                onClick={() => {
                                    subwayRoutesConfig.arrayHelpers?.push({
                                        station: '',
                                        duration: '',
                                    });
                                }}
                            >
                                Добавить метро
                            </ButtonNew>
                        ),
                    },
                ]);
            }

            return {
                rows,
                rowStyles,
            };
        },
        [isAdmin]
    );

    const modals: {
        content: React.ReactNode;
        active: boolean;
        setActive: React.Dispatch<React.SetStateAction<boolean>>;
    }[] = [
        {
            content: (
                <UploadPhotosDropzone
                    closeDropzone={() => setUploadPhotosModalActive(null)}
                />
            ),
            active: !!uploadPhotosModalActive,
            setActive: () => setUploadPhotosModalActive(null),
        },
        {
            content: (
                <ToggleGroupWorkoutsContent
                    onClose={() => setActiveGroupWorkoutsModal(null)}
                />
            ),
            active: !!activeGroupWorkoutsModal,
            setActive: () => setActiveGroupWorkoutsModal(null),
        },
    ];

    const getFormSections = React.useCallback(
        ({
            values,
            setFieldValue,
            errors,
        }: {
            values: AddGymFormValues;
            setFieldValue: TFormikSetFieldValue<FormikValues>;
            errors: TFormikErrors<AddGymFormValues>;
        }): FormSectionProps<FormikValues>[] => {
            const commonArgs = { values, errors, setFieldValue };

            return [
                {
                    ...getParameterRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                },
                {
                    ...getTagsRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Теги',
                    subtitle:
                        'Выберите теги, которые соответствуют площадке, что бы упростить поиск клиенту',
                    withDivider: true,
                },
                {
                    ...getGymInfoRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Информация о зале',
                    subtitle: 'Расположение',
                    subtitleStyle: {
                        fontWeight: 'bold',
                        color: 'rgba(0, 0, 0, 1)',
                        paddingTop: 28,
                    },
                },
                {
                    ...getGymWorkingHoursRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Время работы',
                    containerStyle: {
                        marginTop:
                            values.workingHours?.length && wasMapShown
                                ? 96 - (values.subwayRoutes?.length ?? 0) * 24
                                : 64,
                    },
                },
                {
                    ...getGymImagesRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Фото зала',
                    subtitle: 'Загрузите до 5 фото с расширением PNG/JPG/WEBP',
                },
                {
                    ...getGymIconRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: '',
                },
                {
                    ...getGymDescriptionRowsConfig(),
                    setFieldValue,
                    errors,
                    title: 'Описание площадки',
                    subtitle: 'Описание площадки',
                },
                {
                    ...getGymContactsRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Контакты площадки',
                    withDivider: true,
                },
                !!values.hasPerMinWorkouts && {
                    ...getGymByMinuteRowsConfig(commonArgs),
                    setFieldValue,
                    errors,
                    title: 'Поминутные тренировки',
                    subtitle: (
                        <div className={styles.sliderMinWorkoutDuration}>
                            <span>Есть минимальное время тренировки</span>
                            <label className={styles.sliderSwitch}>
                                <Field
                                    disabled={isAdmin}
                                    className={styles.toggle}
                                    type="checkbox"
                                    name="hasMinWorkoutDuration"
                                    onClick={(e: any) => {
                                        if (e.target.value === 'true') {
                                            setFieldValue(
                                                'minWorkoutDuration',
                                                ''
                                            );
                                        }
                                    }}
                                />
                                <span className={styles.slider} />
                            </label>
                        </div>
                    ),
                },
            ].filter(Boolean) as FormSectionProps<FormikValues>[];
        },
        [
            getParameterRowsConfig,
            getTagsRowsConfig,
            getGymInfoRowsConfig,
            getGymWorkingHoursRowsConfig,
            getGymImagesRowsConfig,
            getGymIconRowsConfig,
            getGymDescriptionRowsConfig,
            getGymContactsRowsConfig,
            getGymByMinuteRowsConfig,
            wasMapShown,
        ]
    );

    const isErrorShown = React.useRef(false);

    const handleRender = React.useCallback(
        ({
            values,
            setFieldValue,
            errors,
            isSubmitting,
        }: FormikProps<AddGymFormValues>) => {
            if (
                isSubmitting &&
                !isEmptyError(errors as IAddGymErrors) &&
                !isErrorShown.current
            ) {
                isErrorShown.current = true;

                scrollToElement(errors as IAddGymErrors);

                setTimeout(() => {
                    isErrorShown.current = false;
                }, 4000);

                const matchedError = (Object.entries(errors) as [
                    string,
                    string
                ][]).find(([, value]) => value?.trim?.());

                if (matchedError) {
                    const [firstError, message] = matchedError;

                    const mappedErrorMessage = fieldErrorsMatrix[firstError];

                    if (mappedErrorMessage) {
                        cogoToast.error(
                            [
                                mappedErrorMessage,
                                (message || 'обязательное поле').toLowerCase(),
                            ].join(' \u2014 '),
                            {
                                position: 'top-right',
                                hideAfter: 4,
                            }
                        );
                    }
                }
            }

            return (
                <Form className={styles.addGymForm}>
                    {modals.map(({ content, active, setActive }, index) => (
                        <Modal
                            key={['modal', index].join('_')}
                            active={active}
                            setActive={setActive}
                        >
                            {content}
                        </Modal>
                    ))}

                    <AddGymSuperAdminHeader
                        legalEntitysOptions={legalEntitysOptions}
                        allManagersOptions={allManagersOptions}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                    />

                    <div className={styles.box}>
                        <h2 className={styles.title}>Параметры площадки</h2>
                        {getFormSections({ values, setFieldValue, errors }).map(
                            renderFormSection({ setFieldValue })
                        )}
                        <button
                            className={classnames(
                                styles.btnSubmit,
                                styles.gutterTop
                            )}
                            type="submit"
                        >
                            Создать площадку
                        </button>
                    </div>
                </Form>
            );
        },
        [legalEntitysOptions, allManagersOptions, getFormSections]
    );

    const header = React.useMemo(
        () => (
            <div className={styles.header}>
                {'Добавить новую фитнес площадку'}
            </div>
        ),
        []
    );

    const activeTooltip = React.useMemo(
        () =>
            Object.entries(tooltips)
                .filter(([, value]) => value)
                .map(
                    ([key]) =>
                        tooltipsMatrix[key as keyof typeof tooltipsMatrix]
                )[0] ?? null,
        [tooltips]
    );

    return (
        <div>
            <TooltipPortal
                target={tooltips.currentAnchor}
                node={activeTooltip?.tooltip}
                nodeWrapperStyle={activeTooltip?.style}
            />
            {header}
            <Formik
                onSubmit={onSubmit}
                enableReinitialize={true}
                validate={validate}
                initialValues={initialValues}
            >
                {handleRender}
            </Formik>
        </div>
    );
});
